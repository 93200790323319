import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Avatar,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  CircularProgress,
  Grid, IconButton,
  Typography,
} from '@mui/material'
import GridOnIcon from '@mui/icons-material/GridOn';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Heading from './Heading'
import BasePage from './BasePage'
import { clearAdmin, exportDraws } from '../../state'


const AdminPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const admin = useSelector(state => state.admin);
  const [isWorking, setWorking] = useState(false);
  const isAdmin = user && user.claims.admin === true;

  useEffect(() => {
    dispatch(clearAdmin());
  }, [dispatch]);

  const doExportDraws = () => {
    setWorking(true);
    dispatch(exportDraws())
      .finally(() => setWorking(false));
  }
  return (
    isAdmin ? <BasePage>
      <Heading title="Administration" />
      <Grid container spacing={2}>
        <Grid item>
          <Card elevation={1} sx={{
            width: {xs: '100%', sm: 312}
          }}>
            <CardActionArea onClick={doExportDraws} disabled={isWorking}>
              <CardHeader
                avatar={<Avatar sx={{bgcolor: 'primary.main'}}><GridOnIcon/></Avatar>}
                title={<Typography variant="body1" color="primary" fontWeight="bold">Export Prize Draws</Typography>}
                subheader="Generate a randomized spreadsheet of the prize draw database."
                sx={{alignItems: 'flex-start'}}
              >
              </CardHeader>
            </CardActionArea>
            <CardActions sx={{justifyContent: 'flex-end'}}>
              {isWorking && <CircularProgress size={24}/> }
              {admin.export && <IconButton href={admin.export} color="primary">
                <DownloadForOfflineIcon/>
              </IconButton>}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </BasePage> : null
  )
};

export default AdminPage;
