import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Avatar,
  Box, Button,
  Grid, Slide,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography, useMediaQuery, useTheme,
} from '@mui/material'
import StarIcon from '@mui/icons-material/Star';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import SportsBarIcon from '@mui/icons-material/SportsBar';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { loadBoard } from '../../state'
import ActivityAvatar from '../../comps/ActivityAvatar'
import KOBLogo from '../../assets/logos/kob.png';
import QOBLogo from '../../assets/logos/qob.png';
import BasePage from './BasePage'
import Heading from './Heading'
import Spinner from '../../comps/Spinner';
import Section from '../../comps/Section';

const ACT_RUN = 'run';
const ACT_RIDE = 'ride';

const ActivitySwitch = styled(Switch)(({theme}) => ({
  '& .MuiSwitch-thumb': {
    color: `${theme.palette.secondary.main} !important`,
  },
  '& .MuiSwitch-track': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    opacity: "0.5 !important",
  }
}));

const Board = ({logo, data, slideProps}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const user = useSelector(state => state.user);

  return (
    <Slide in={true} {...slideProps}>
      <Table sx={{backgroundColor: 'common.white'}}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={isSmall?3:2}>
              <Box component="img" src={logo} sx={{height: {xs: '3rem', sm: '4rem'}, width: 'auto'}}/>
            </TableCell>
            <TableCell>
              <Avatar sx={{
                margin: 'auto',
                width: '3rem', height: '3rem',
                backgroundColor: 'secondary.main'
              }}>
                <SportsBarIcon/>
              </Avatar>
            </TableCell>
            <TableCell>
              <Avatar sx={{
                margin: 'auto',
                width: '3rem', height: '3rem',
                backgroundColor: 'secondary.main'
              }}>
                <ConfirmationNumberIcon />
              </Avatar>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((p,i) => <TableRow key={p.id} sx={{
            border: (p.id === user.id) ? `4px solid ${theme.palette.primary.main}` : 'initial'
          }}>
            <TableCell sx={{textAlign: 'center'}}>
              {i === 0 ? <Avatar sx={{
                margin: 'auto',
                width: '2rem', height: '2rem',
                backgroundColor: 'primary.main'
              }}>
                <StarIcon />
              </Avatar> : <Typography>{i+1}</Typography>}
            </TableCell>
            {isSmall &&
            <TableCell>
              <Box component="img" src={p.avatar} sx={{width: '3rem', height: '3rem', borderRadius: '100%'}}/>
            </TableCell>}
            <TableCell>
              <Typography variant="subtitle1">
                {p.name}
              </Typography>
            </TableCell>
            <TableCell sx={{textAlign:'center'}}>
              <Typography>{p.num_pints}</Typography>
            </TableCell>
            <TableCell sx={{textAlign:'center'}}>
              <Typography>{p.num_draws}</Typography>
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </Slide>
  )
};

const LeaderboardPage = () => {
  const board = useSelector(state => state.board);
  const dispatch = useDispatch();
  const [act, setAct] = useState(ACT_RIDE);

  useEffect(() => {
    if (!board) {
      dispatch(loadBoard());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board]);

  return (
    <BasePage>
      {!board && <Spinner/>}
      {board && 
      <Stack gap={1}>
        <Section>
          <Heading title="Trail Heroes"/>
          <Table sx={{backgroundColor: 'common.white'}}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography gutterBottom>
                    Trail heroes make donations to segments and allow P4P participants to earn prize draws. Without donations, no prizes are unlocked 
                    , and no prize draws awarded. Become a hero today, join this elite list, and make a segment donation to keep the fun going.
                  </Typography>
                  <Button variant="contained" color="primary" size="small" href="/segments">Donate Now</Button>
                </TableCell>
                <TableCell sx={{verticalAlign: 'bottom'}}>
                  <Avatar sx={{
                    margin: 'auto',
                    width: '3rem', height: '3rem',
                    backgroundColor: 'secondary.main'
                  }}>
                    <AttachMoneyIcon/>
                  </Avatar>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {board.donors.map((d,i) => <TableRow key={i}>
                <TableCell sx={{textAlign: 'center'}}>
                  {d.logo && <Box component="img">
                    </Box>}
                  {!d.logo && <Avatar>
                    {d.name.split(" ").slice(0,2).map(it => it.toUpperCase()[0]).join("")}
                  </Avatar>}
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">
                    {d.name}
                  </Typography>
                </TableCell>
                <TableCell sx={{textAlign:'center'}}>
                  <Typography variant="h4" fontWeight={900} color="primary">${d.amount}</Typography>
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </Section>
        <Section>
          <Heading title="Leaderboard" extra={<Stack direction="row" sx={{mb: 1}}>
            <ActivityAvatar activity="ride" color="primary"/>
            <ActivitySwitch checked={act===ACT_RUN}
                            onChange={() => setAct(act === ACT_RUN ? ACT_RIDE : ACT_RUN)}/>
            <ActivityAvatar activity="run" color="primary"/>
          </Stack>}/>
          
          <Grid container spacing={2  }>
            <Grid item xs={12} md={6}>
              <Board logo={KOBLogo} data={board[act].kob} slideProps={{direction:'right'}}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Board logo={QOBLogo} data={board[act].qob} slideProps={{direction:'left'}}/>
            </Grid>
          </Grid>
        </Section>
      </Stack>}
    </BasePage>
  )
}

export default LeaderboardPage;
