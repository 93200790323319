import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import {
  Alert, AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl, FormControlLabel,
  Grid,
  Radio, RadioGroup,
  Stack,
  Step, StepContent, StepLabel, Stepper,
  SvgIcon,
  Typography,
  Zoom,
} from '@mui/material'
import Panel from '../../comps/Panel'
import Em from '../../comps/Em'
import ErrorAlert from '../../comps/ErrorAlert'
import TextField from '../../comps/TextField'
import { Text2 } from '../../comps/Type'
import {
  checkout,
  declineMembership,
  MEMBERSHIP_INACTIVE,
  MEMBERSHIP_UNVERIFIED,
  MEMBERSHIP_VERIFIED,
  resetMembership,
  verifyMembership,
} from '../../state'
import { ReactComponent as ConnectIcon } from '../../assets/strava/connect.svg';
import MembershipCard from '../../assets/images/membership-card.jpg';


const ConnectStrava = () => {
  const callback = `${window.location.origin}/app/strava`
  return (
    <Stack alignItems="flex-start" gap={1}>
      <Typography>
        In order to track your participation for prize draws, we use Strava. You can use a free Strava account, and keep your rides
        private if you choose.
      </Typography>
      <Button component="a" sx={{padding:0}}
              href={`https://www.strava.com/oauth/authorize?client_id=54627&redirect_uri=${callback}&response_type=code&approval_prompt=auto&scope=activity:read,activity:read_all`}>
          <SvgIcon component={ConnectIcon} inheritViewBox sx={{width: '100%', height: 'auto'}}/>
      </Button>
    </Stack>
  )
}

const MembershipVerify = () => {
  const dispatch = useDispatch();
  const membership = useSelector(state => state.user.membership || MEMBERSHIP_UNVERIFIED);
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState();
  const [type, setType] = useState('non-member');
  const [memberNum, setMemberNum] = useState('');
  const [isValid, setValid] = useState(true);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if (memberNum === '') {
      setValid(true);
      setReady(false);
    }
    else if (typeof memberNum === 'number') {
      setValid(true);
      setReady(true);
    }
    else if (typeof memberNum != "string") {
      setValid(false);
      setReady(false);
    }
    else {
      const v = !isNaN(memberNum) && !isNaN(parseFloat(memberNum));
      setValid(v);
      setReady(v);
    }
  }, [memberNum])

  const doVerify = () => {
    setError(null);
    setIsWorking(true);

    dispatch(verifyMembership(memberNum)).unwrap()
      .then(result => {
        if (result.status === 'error') {
          if (result.code === 'not_found') {
            setError({
              title: 'Unrecognized Membership Number',
              message: "Sorry, we couldn't find a membership corresponding to that number. Please double check the number and try again",
            });
          }
        }
        console.log(result);
      })
      .catch(error => {
        console.error(error);
        setError({
          title: 'Membership Verification Failed',
          message: 'Sorry, we were unable to verify your membership due to a system error. Please try again or email us at contact@camba.ca and we will sort you out.'
        });
      })
      .finally(() => setIsWorking(false));
  }

  const doDecline = () => {
    setError(null);
    setIsWorking(true);

    dispatch(declineMembership()).unwrap()
      .finally(() => setIsWorking(false));
  }

  return (
    <Stack alignItems="flex-start" gap={2}>
      <Typography>
        The entry fee for the Pedal for Pints prize draw is <Em>$20</Em> for CAMBA members, and <Em>$40</Em> for non CAMBA members. Pick
        the appropriate option below.
      </Typography>
      {type === 'non-member' && <>
        {membership === MEMBERSHIP_UNVERIFIED && <Alert severity="info" variant="filled">
          If you would like to get a CAMBA membership, you can do so online. Just follow <a href="https://camba.tidyhq.com/public/membership_levels" target="_blank" rel="noreferrer noopener">this link</a> to get started. Once you have your membership card, proceed to the next step.
        </Alert>}
        {membership === MEMBERSHIP_INACTIVE && <Alert severity="warning" variant="filled">
          <AlertTitle>Membership Expired</AlertTitle>
          Our records show that your CAMBA membership has expired. If you would like to renew it, you can do so online. Just follow <a href="https://camba.tidyhq.com/public/membership_levels" target="_blank" rel="noreferrer noopener">this link</a> to get started. Once you have your new membership card, proceed to the next step.
          Or you can choose to proceed as a non member.
        </Alert>}
      </>}
      <FormControl>
        <RadioGroup defaultValue="non-member" value={type} onChange={evt => setType(evt.target.value)}>
          <FormControlLabel value="non-member" control={<Radio />} label="I am NOT an active CAMBA member, and would like to participate without a membership" />
          <FormControlLabel value="member" control={<Radio />} label="I am an active CAMBA member and would like to verify my membership" />
        </RadioGroup>
      </FormControl>
      {type === 'member' && <>
        <Alert severity="info" variant="filled">
          <AlertTitle>Can't find your Membership Card?</AlertTitle>
          If you can't find your membership card, no problem. Just email us at  <a href="mailto:contact@camba.ca?">contact@camba.ca</a> and
          we'll re-send it to you.
        </Alert>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Text2>On your membership card, there is a 7 or 8 digit membership number in the lower left corner of the card. Enter this number below.</Text2>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Box component="img" src={MembershipCard} sx={{width: '100%'}}/>
          </Grid>
        </Grid>
        {membership === MEMBERSHIP_INACTIVE && <Alert severity="warning" variant="filled">
          <AlertTitle>Membership Expired</AlertTitle>
          Our records show that your CAMBA membership has expired. If you would like to renew it, you can do so online. Just follow <a href="https://camba.tidyhq.com/public/membership_levels" target="_blank" rel="noreferrer noopener">this link</a> to get started. Once you have your new membership card, proceed to the next step.
          Or you can choose to proceed as a non member.
        </Alert>}
        <TextField label="Membership Number"  placeholder="Please enter your membership number here"
                   value={memberNum} onChange={evt => setMemberNum(evt.target.value)} fullWidth error={!isValid}
                   helperText={isValid?null:"Your membership number must be a number."}
        />
        <Button variant="contained" size="large" disabled={isWorking || !isReady} sx={{display: 'flex', gap: 1}} onClick={doVerify}>
          {isWorking && <CircularProgress size={24}/>} Verify Membership
        </Button>
      </>
      }
      {type === 'non-member' && <>
        <Button variant="contained" size="large" disabled={isWorking} sx={{display: 'flex', gap: 1}} onClick={doDecline}>
          {isWorking && <CircularProgress size={24}/>} Proceed
        </Button>
      </>}
      {error && <ErrorAlert error={error}/> }
    </Stack>
  )
}

const PayFee = () => {
  const dispatch = useDispatch();
  const isMember = useSelector(state => state.user.membership === MEMBERSHIP_VERIFIED);
  const [isPaying, setIsPaying] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [error, setError] = useState();

  const type = isMember ? 'member' : 'non-member';

  const doCheckout = () => {
    setIsPaying(true);
    dispatch(checkout(type)).unwrap()
      .then((session) => {
        window.open(session.url, "_parent");
      })
      .catch(setError)
      .finally(() => setIsPaying(false));
  }

  const doReset = () => {
    setIsResetting(true);
    dispatch(resetMembership()).unwrap()
      .finally(() => setIsResetting(false));
  }

  return (
    <Stack alignItems="flex-start" gap={2}>
      <Typography>
        The entry fee for the Pedal for Pints prize draw for a <Em>{type}</Em> is <Em>${isMember?'20':'40'}</Em>. You can pay the fee
        online by clicking the Checkout button below.
      </Typography>
      {error && <ErrorAlert error={error}/> }
      <Stack direction="row" gap={1}>
        <Button variant="contained" size="large" disabled={isPaying} sx={{display: 'flex', gap: 1}} onClick={doCheckout}>
          {isPaying && <CircularProgress size={24}/>} Pay - ${isMember?'20':'40'}
        </Button>
        <Button variant="outlined" size="large" disabled={isPaying || isResetting} sx={{display: 'flex', gap: 1}} onClick={doReset}>
          {isResetting && <CircularProgress size={24}/>} Back to Membership
        </Button>
      </Stack>

    </Stack>
  )
}

const Setup = () => {
  const membership = useSelector(state => state.user.membership || MEMBERSHIP_UNVERIFIED);
  const isPaid = useSelector(state => state.user.payment_id != null);
  return (
    <Stack>
      <Zoom in={true}>
        <Box>
          <Panel
            icon={<RocketLaunchIcon/>}
            title={
              <Typography variant="h4" fontWeight="bold" color="primary" gutterBottom>Welcome</Typography>
            }
            text={
              <Box>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  Your P4P account is all set up and ready to go. If all you are interested in is the free beer, you're done and can skip any further steps.
                  Just go ride one of the loops, take the selfies, and then head to Sheepdog Brewing after.
                </Typography>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                However, if you're interested in participating in the prize draws, there a couple of additional steps of
                payment, and connecting your Strava account.
                </Typography>
              </Box>
            }
          />
        </Box>
      </Zoom>
      <Container maxWidth="md" sx={{py:2}}>
        <Stepper activeStep={([MEMBERSHIP_UNVERIFIED,MEMBERSHIP_INACTIVE].includes(membership))?0:(isPaid?2:1)} orientation="vertical">
          <Step>
            <StepLabel optional={<Typography variant="caption" fontStyle="italic" textTransform="capitalize">{membership}</Typography>}>
              Membership
            </StepLabel>
            <StepContent>
              <MembershipVerify/>
            </StepContent>
          </Step>
          <Step>
            <StepLabel optional={isPaid?<Typography variant="caption" fontStyle="italic">Paid</Typography>:null}>
              Payment
            </StepLabel>
            <StepContent>
              <PayFee/>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              Strava
            </StepLabel>
            <StepContent>
              <ConnectStrava/>
            </StepContent>
          </Step>
        </Stepper>
      </Container>
    </Stack>
  )
}

export default Setup;
