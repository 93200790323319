import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux'
import Root from '../../comps/Root';
import Spinner from '../../comps/Spinner';
import ErrorAlert from '../../comps/ErrorAlert';
import { connectStrava } from '../../state'


const StravaAuthPage = () => {
  const dispatch = useDispatch();
  const [isWorking, setWorking] = useState(true);
  const [error, setError] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('code')) {
      dispatch(connectStrava({code: params.get('code'), scope: params.get('scope')})).unwrap()
        .then(() => navigate('/app'))
        .catch(setError)
        .finally(() => setWorking(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Root>
      {isWorking && <Spinner centered={true}/>}
      {!isWorking && error && <ErrorAlert error={error}/>}
    </Root>
  )
}

export default StravaAuthPage
