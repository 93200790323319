import { Link } from 'react-router-dom'
import { Box, Button, Typography, Zoom } from '@mui/material'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Panel from '../../comps/Panel';
import Em from '../../comps/Em'


const Launchpad = () => {
  return (
    <Zoom in={true}>
      <Box>
        <Panel
          icon={<RocketLaunchIcon/>}
          title={
            <Typography variant="h4" fontWeight="bold" color="primary" gutterBottom>Ready, Set, Go!</Typography>
          }
          text={
            <Box>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                You're all good to go. On <Em>August 5</Em> you can head out and ride the loops, and then check back here to track your progress earning prize draws. See you out on the trails!
              </Typography>
            </Box>
          }
          button={<Button variant="outlined" component={Link} to="/map">Show me the Loops</Button> }
        />
      </Box>
    </Zoom>
  )
}

export default Launchpad;
