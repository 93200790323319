import React from 'react';
import { useSelector } from 'react-redux'
import BasePage from './BasePage'
import Dash from './Dash'
import Setup from './Setup'

const AppPage = () => {
  const isSetup = useSelector(state => state.user?.strava_id != null && state.user?.payment_id != null);
  return (
      <BasePage>
        {isSetup ? <Dash/> : <Setup/>}
      </BasePage>
  )
}

export default AppPage;
