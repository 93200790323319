import {styled, TextField as MUITextField} from "@mui/material";

const TextField = styled(MUITextField)(
  ({theme}) => ({
    '& input': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      fontSize: theme.typography.body1.fontSize
    },
    '& .MuiInputBase-inputAdornedStart': {
      paddingLeft: theme.spacing(1)
    }
  })
)

export default TextField;

