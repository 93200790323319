import {useSelector} from 'react-redux';
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Grid,
  Slide,
} from '@mui/material'
import Heading from './Heading';
import EffortCard from './EffortCard'


const LastEffort = () => {
  const data = useSelector(state => state.data);
  // const segments = useSelector(state => state.segments || {});
  const effort = data.efforts[0];

  return (
    <Box width="100%">
      <Heading title="Last Effort" extra={<Button component={Link} to="/app/efforts" variant="outlined" color="secondary" sx={{
          py:0, display: 'inline-flex', alignItems: 'flex-end'
        }}>All My Efforts</Button>}/>
      <Grid container spacing={1} justifyContent="flex-start">
        <Grid item xs={12} md={5}>
          <Slide direction="right" in={true}>
            <Box w="100%">
              <EffortCard effort={effort}/>
            </Box>
          </Slide>
        </Grid>
        {/*<Grid item xs={12} md={7}>*/}
        {/*  {segments && <Stack gap={0.5}>*/}
        {/*    {segmentsAndDraws.length === 0 && <Box>*/}
        {/*      <Typography variant="h5" transform="uppercase" fontWeight={900} sx={{color: 'quaternary.main'}}>Oh No!</Typography>  */}
        {/*      <Text1>Your effort didn't earn any prize draws.</Text1>*/}
        {/*      <Text3 sx={{mt:1}}>*/}
        {/*        This is likely because the segments you hit don't have any prize draws left on them. But fear not, it's easy to add more. All someone needs to do is */}
        {/*        donate to the segment, and that will release more prize draws.*/}
        {/*      </Text3>*/}
        {/*      <Button variant="contained" color="primary" sx={{mt:1}} href="/segments">Donate Now</Button>*/}
        {/*    </Box>}*/}
        {/*    {segmentsAndDraws.map((it,i) => {*/}
        {/*      const sponsor = it.segment.sponsor;*/}
        {/*      return <Slide key={it.segment.id} direction="left" in={true} style={{*/}
        {/*        transitionDelay: `${i*150}ms`*/}
        {/*      }}>*/}
        {/*        <Paper elevation={0} sx={{bgcolor: 'primary.main', color: 'white'}} square={true}>*/}
        {/*          <Box display="flex" gap={1} alignItems="center" pr={0.5}>*/}
        {/*            {sponsor.logo && <Box component="img" src={sponsor.logo} width={64} height={64} p={0.5} sx={{*/}
        {/*              objectFit: 'contain',*/}
        {/*              bgcolor: 'rgba(255,255,255,0.9)'*/}
        {/*              }}></Box>}*/}
        {/*            <Box sx={{flex:1}}>*/}
        {/*              <Typography variant="h6" fontWeight={900} lineHeight={1}>{it.segment.prize.name}</Typography>*/}
        {/*              <Typography variant="body1">{it.segment.name}</Typography>*/}
        {/*            </Box>*/}
        {/*            <Box position="relative">*/}
        {/*                {it.segment.raised < it.segment.target && <SegmentLockIcon segment={it.segment} sx={{*/}
        {/*                  opacity: 0.75,*/}
        {/*                  position: 'absolute',*/}
        {/*                  top: "50%",*/}
        {/*                  left: "50%",*/}
        {/*                  transform: 'translate(-50%,-50%)'*/}
        {/*                }}/>}*/}
        {/*                <Typography variant="h4" fontWeight={900} color="primary" sx={{*/}
        {/*                    display: 'flex',*/}
        {/*                    width: '56px',*/}
        {/*                    height: '56px',*/}
        {/*                    justifyContent: 'center',*/}
        {/*                    alignItems: 'center',*/}
        {/*                    bgcolor: 'white',*/}
        {/*                    borderRadius: '100%'*/}
        {/*                }}>*/}
        {/*                +{it.draws}*/}
        {/*              </Typography>*/}
        {/*            </Box>*/}
        {/*          </Box>*/}
        {/*      </Paper>*/}
        {/*      </Slide>*/}
        {/*    })}*/}
        {/*  </Stack>}*/}
        {/*</Grid>*/}
      </Grid>
      <Grid container sx={{mt:4}}>
        {/*<Grid item xs={12} md={5}>*/}
        {/*  <Box display="flex" flexWrap={true} gap={1} width="100%">*/}
        {/*      {segmentsAndDraws.map(it => it.segment.donors).flat().map((d,i) => <Zoom in={true} key={i} style={{*/}
        {/*        transitionDelay: `${i*100}ms`*/}
        {/*      }}>*/}
        {/*        <Box sx={{*/}
        {/*          width: "6rem",*/}
        {/*          height: "6rem",*/}
        {/*          backgroundColor: 'primary.main',*/}
        {/*          color: 'white',*/}
        {/*          borderRadius: "100%",*/}
        {/*          display: "flex",*/}
        {/*          alignItems: "center",*/}
        {/*          justifyContent: "center",*/}
        {/*          textAlign: "center",*/}
        {/*          padding: "0.25rem",*/}
        {/*          fontWeight: 500*/}
        {/*        }}>*/}
        {/*          {d.name}*/}
        {/*        </Box>*/}
        {/*      </Zoom>)}*/}
        {/*  </Box>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} md={7}>*/}
        {/*  {segmentsAndDraws.length > 0 && <Stack gap={1} alignItems="flex-start">*/}
        {/*      <Typography variant="h5" fontWeight={900} fontStyle="italic" color="primary">Thanks to our Trail Heroes!</Typography>*/}
        {/*      <Text3>*/}
        {/*          Trail Heroes are the amazing people that have donated to the segments in this effort, and allowed you to earn a few */}
        {/*          draws for the awesome prizes. If you haven't made a donation yourself yet, there is no better time.*/}
        {/*      </Text3>*/}
        {/*      <Button variant="contained" color="primary" href="/segments">*/}
        {/*        Donate Now*/}
        {/*      </Button>*/}
        {/*  </Stack>}*/}
        {/*</Grid>*/}
      </Grid>
    </Box>
  )
}

export default LastEffort;
