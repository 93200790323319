import { Avatar } from '@mui/material'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'

const ActivityAvatar = ({activity, disabled, color='secondary', sx}) => {
  return (
    <Avatar sx={{backgroundColor: disabled === true ? 'gray.100' : `${color}.main`, ...sx}}>
      {activity === 'run' ? <DirectionsRunIcon/> : <DirectionsBikeIcon/>}
    </Avatar>
  )
}

export default ActivityAvatar
