import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import Panel from '../../comps/Panel'
import Spinner from '../../comps/Spinner'
import ErrorAlert from '../../comps/ErrorAlert'
import BasePage from './BasePage'
import { verifyCheckout } from '../../state'

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const PayPage = () => {
  const dispatch = useDispatch();
  const [isWorking, setIsWorking] = useState(true);
  const query = useQuery();
  const [error, setError] = useState();

  const isSuccess = useMemo(() => query.get('result') === 'success', [query]);

  useEffect(() => {
    if (isSuccess) {
      const session = query.get('session');
      if (session) {
        dispatch(verifyCheckout(session)).unwrap()
          .then((s) => console.log(s))
          .catch(setError)
          .finally(() => setIsWorking(false));
      }
    }
    else {
      setIsWorking(false);
    }
  }, [dispatch, isSuccess, query])

  return (
    <BasePage>
      {error && <ErrorAlert error={error}/> }
      {isWorking ? <Spinner/> :
      <Panel
        icon={isSuccess ? <CreditScoreIcon/> : <CreditCardOffIcon/>}
        title={
          <Typography variant="h4" fontWeight="bold" color="primary" gutterBottom>
            {isSuccess ? 'Payment Successful' : 'Payment Cancelled'}
          </Typography>
        }
        text={
          <Box>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              {isSuccess ?
                `Your payment was successful, thank you! One more step and you're up and running. Return to setup to connect your Strava account.` :
                `Your payment was cancelled. Whenever you're ready, just return to the setup page to try again.`}
            </Typography>
          </Box>
        }
        button={<Button variant="outlined" component={Link} to="/app">
          Return to Setup
        </Button>}
      />}
    </BasePage>
  )
}

export default PayPage;