import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import Spinner from '../../comps/Spinner'
import Launchpad from './Launchpad';
import Stats from './Stats'
import LastEffort from './LastEffort'
import { loadData } from '../../state'


const Dash = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.data);
  //const isMember = useSelector(state => state.user.member === true);

  useEffect(() => {
    dispatch(loadData());
    // dispatch(loadSegments());
  }, [dispatch]);

  return (
    <>
      {!data && <Spinner centered={true}/> }
      {data && <>
        {data.efforts.length === 0 && <Launchpad/>}
        {data.efforts.length > 0 && <Stack gap={4} sx={{width:'100%'}}>
          <Stats/>
          <LastEffort/>
        </Stack>}
      </>
      }
    </>
    // <>

    //   {/*{data && <Box mb={4}>*/}
    //   {/*  <Notice/>*/}
    //   {/*</Box>}*/}
    //   {data && data.efforts.length === 0 && <Launchpad/>}
    //   {data && data.efforts.length > 0 && <Stack gap={4}>
    //     {!isMember && <MemberStatus/>}
    //     <LastEffort/>
    //     <Stats/>
    //     {/* <Segments/> */}
    //   </Stack>}
    // </>
  )
}

export default Dash;
