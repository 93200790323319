import { Box } from '@mui/material'

const A = ({children, ...props}) => {
  return (<Box component="a" {...props} sx={{
    color: 'info.main', fontWeight: 'bold', textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }} >
    {children}
  </Box>)
}

export default A;
