import React from 'react'
import { useSelector } from 'react-redux'
import Container from '../../comps/Container'
import Root from '../../comps/Root'
import Spinner from '../../comps/Spinner'
import Header from './Header'
import Login from './Login'

const BasePage = ({children}) => {
  const isInit = useSelector(state => state.init === true && state.work !== true);
  const isLoggedIn = useSelector(state => state.user != null);
  return (
    <Root>
      {!isInit && <Spinner centered={true}/>}
      {isInit && <>
        {!isLoggedIn && <Login/>}
        {isLoggedIn && <>
          <Header/>
          <Container maxWidth="lg">
            {children}
          </Container>
        </>}
      </>}
    </Root>
  )
}

export default BasePage;
