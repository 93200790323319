import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, CircularProgress, InputAdornment, Stack, SvgIcon } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import LockIcon from '@mui/icons-material/Lock'
import LoginIcon from '@mui/icons-material/Login'
import Container from '../../comps/Container'
import { Text1 } from '../../comps/Type'
import TextField from '../../comps/TextField'
import ErrorAlert from '../../comps/ErrorAlert'
import Section from '../../comps/Section'
import { ReactComponent as P4PLogo } from '../../assets/logos/p4p-logo.svg'
import { signin } from '../../state'

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState();
  const isReady = email.length > 0 && password.length > 0;

  const login = () => {
    setIsWorking(true);
    dispatch(signin({email, password})).unwrap()
      .catch(setError)
      .finally(() => setIsWorking(false));
  }

  return (
    <Section name="login">
      <Container maxWidth="md">
        <Stack alignItems="center" gap={2}>
          <SvgIcon component={P4PLogo} inheritViewBox sx={{
            width: "100%",
            height: "auto",
            maxWidth: "512px"
          }}/>
          <Text1>
            Please enter your email and password to login.
          </Text1>
          <TextField
            label="Email Address"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon/>
                </InputAdornment>
              )
            }}
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon/>
                </InputAdornment>
              )
            }}
          />
          {error && <ErrorAlert error={error}/>}
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={!isReady || isWorking}
            onClick={login}
            endIcon={isWorking ? <CircularProgress size={24}/> : <LoginIcon/>}
          >
            Login
          </Button>
          <Stack direction="row" gap={4}>
            <Button component={Link} to="/password" color="primary"
            >
              I Forgot my Password
            </Button>
            <Button component={Link} to="/signup" color="primary"
            >
              I Need an Account
            </Button>
          </Stack>

        </Stack>
      </Container>
    </Section>
  )
};

export default Login;