import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, AlertTitle, Button, CircularProgress, Grid, Stack, SvgIcon } from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import Root from '../../comps/Root'
import Section from '../../comps/Section'
import { Text1, Text2, Text3, Title } from '../../comps/Type'
import Container from '../../comps/Container'
import TextField from '../../comps/TextField'
import ErrorAlert from '../../comps/ErrorAlert'
import { ReactComponent as P4PLogo } from '../../assets/logos/p4p-logo.svg'
import { signup } from '../../state'


const SignupPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState('');
  const [isWorking, setIsWorking] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [error, setError] = React.useState();

  const isReady = email.length > 0
    && email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const send = () => {
    setIsWorking(true);
    dispatch(signup(email)).unwrap()
      .then(() => {
        setSent(true);
      })
      .catch(setError)
      .finally(() => setIsWorking(false));
  }
  return (
    <Root sx={{ backgroundColor: 'white' }}>
      <Section>
        <Container maxWidth="md">
          <Grid container alignItems="center" justifyContent="center" spacing={1}>
            <Grid item md={6}>
              <SvgIcon component={P4PLogo} inheritViewBox sx={{
                width: "100%",
                height: "auto",
                maxWidth: "512px"
              }}/>
            </Grid>
            <Grid item md={6}>
              <Stack alignItems="flex-start" gap={2}>
                <Title>Register</Title>
                {sent ?
                  <Text2>
                    Thanks! Check your inbox for a confirmation email, and a link to finish registration.
                  </Text2> :
                  <>
                    <Text1>
                      To get started, enter your email address below.
                    </Text1>
                    <TextField label="Email Address" type="email"
                               value={email} onChange={(e) => setEmail(e.target.value)}
                               fullWidth/>
                    {error && <>
                      {error.code === 'user-exists' && <Alert severity="warning" action={<Button component={Link} to="/app">Login</Button>}>
                        <AlertTitle>Email Already Registered</AlertTitle>
                        <Text3>It looks like this email has already been registered. You should log in instead.
                          If you forgot your password, no worries, you can recover it.</Text3>
                      </Alert>}
                      {error.code !== 'user-exists' && <ErrorAlert error={error}/>}
                    </>}
                    <Button variant="contained" color="primary" size="large" disabled={!isReady || isWorking} onClick={send}
                            startIcon={isWorking ? <CircularProgress size={24} sx={{color:'inherit'}}/> : <SendIcon/>}>
                      Let's Go
                    </Button>
                  </>
                }
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Root>
  )
};

export default SignupPage;