import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Alert, AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Link,
  Typography,
} from '@mui/material'
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import BasePage from './BasePage'
import Panel from '../../comps/Panel'
import A from '../../comps/A'
import TextField from '../../comps/TextField'
import { verifyMembership } from '../../state'


const MembershipPage = () => {
  const [isOpen, setOpen] = useState(false);
  const [isWorking, setWorking] = useState(false);
  const [membershipId, setMembershipId] = useState('');
  const [result, setResult] = useState('');
  const dispatch = useDispatch();

  const open = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  const verify = () => {
    setWorking(true);
    dispatch(verifyMembership(membershipId))
      .then(result => setResult(result.payload.code))
      .finally(() => setWorking(false));
  };

  return (
    <BasePage>
      <Panel
        icon={<LoyaltyIcon/>}
        title={
          <Typography variant="h5" fontWeight="bold" color="primary" gutterBottom>Verify your CAMBA Membership</Typography>
        }
        text={
          <Box>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Let's get your CAMBA membership sorted so you are all set for the grand prize draw. Below you'll find
              information about how to verify your membership, based on your situation.
            </Typography>
          </Box>
        }
      >
      </Panel>
      <Box width="100%" mt={4}>
        <Typography variant="h6" fontWeight={900} color="primary" gutterBottom>I don't yet have a membership</Typography>
        <Typography>
          No problem, CAMBA memberships are cheap, and available online. Here is what you need to do:
        </Typography>
        <Box component="ol" sx={{pl: 3}}>
          <li>
            Visit our <A href="https://camba.ca/membership">main website</A> and click the <em>Join Now</em> button.
          </li>
          <li>
            Follow the instructions to register for a membership.
          </li>
          <li>
            Shortly after registering, you will receive an email with a membership card.
          </li>
          <li>
            In the bottom left hand corner of the card, will be a 6 or 7 digit number. This is your membership number.
          </li>
          <li>
            Click <Button variant="outlined" size="small" onClick={open}>Verify</Button> and enter in your membership number.
          </li>
          <li>
            If you run into any problems, feel free to email us at <A href="mailto:contact@camba.ca?subject=Verify Membership for P4P">contact@camba.ca</A> and
            we'll happily help you.
          </li>
        </Box>
      </Box>

      <Box width="100%" mt={4}>
        <Typography variant="h6" fontWeight={900} color="primary" gutterBottom>I already have a membership</Typography>
        <Typography>
          In this case, we probably weren't able to match your email address to our membership database. Here is what you need to do:
        </Typography>
        <Box component="ol" sx={{pl: 3}}>
          <li>
            When you signed up for a membership, you should have received an email with a membership card. The email would have been
            sent from membership@camba.ca.
          </li>
          <li>
            Attached to this email, is a membership card. In the bottom left hand corner of the card, will be a 6 or 7 digit number. This is your membership number.
          </li>
          <li>
            Click <Button variant="outlined" size="small" onClick={open}>Verify</Button> and enter in your membership number.
          </li>
          <li>
            If you run into any problems, feel free to email us at <A href="mailto:contact@camba.ca?subject=Verify Membership for P4P">contact@camba.ca</A> and
            we'll happily help you.
          </li>
        </Box>
      </Box>

      <Box width="100%" mt={4}>
        <Typography variant="h6" fontWeight={900} color="primary" gutterBottom>I don't want a membership</Typography>
        <Typography>
          In this case, you're more than welcome to keep pedalling, and you can still earn pints for each loop that you do, however
          you won't be eligible for the grand prize draw.
        </Typography>
      </Box>
      <Dialog open={isOpen} onClose={close}>
        <DialogTitle>Verify Membership</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{mb: 2}}>
            Please enter the 6 or 7 digit number from your membership card below.
          </DialogContentText>
          <TextField autoFocus fullWidth value={membershipId} onChange={evt => setMembershipId(evt.target.value)} sx={{mb: 1}}/>
          {result && result === 'active' && <Alert severity="success">
            <AlertTitle>Success!</AlertTitle>
            Your membership has been verified. Thanks for supporting CAMBA and good luck in the prize draw!
          </Alert>}
          {result && result === 'not_found' && <Alert severity="warning"
              action={<Button sx={{whiteSpace: 'nowrap'}} component="a" href={`mailto:contact@camba.ca?subject=Verify membership ${membershipId}`}>Contact Us</Button>}>
            <AlertTitle>Membership Not Found</AlertTitle>
            We weren't able to find this membership in our system. Try again, or, if you are sure you have typed it in correctly give us a
            shout and we'll help you out.
          </Alert> }
          {result && (result === 'inactive' || result === 'cancelled')&& <Alert severity="warning"
              action={<Button sx={{whiteSpace: 'nowrap'}} component="a" href={`https://camba.ca/membership`}>Renew Now</Button>}>
            <AlertTitle>Membership Inactive</AlertTitle>
            This membership has expired or has been cancelled. Head on over to our website to renew it.
          </Alert> }
        </DialogContent>
        <DialogActions>
          {result === 'active' && <Button onClick={close} component={Link} href="/app">Done</Button>}
          {result !== 'active' && <Button onClick={close}>Cancel</Button>}
          {result !== 'active' && <Button onClick={verify} variant="outlined" disabled={isWorking}>Verify</Button>}
        </DialogActions>
      </Dialog>
    </BasePage>
  )
}

export default MembershipPage;
