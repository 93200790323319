import { useState } from 'react';
import { first, values } from 'lodash'
import {
  Badge as MUIBadge,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, Skeleton, styled,
  Typography,
} from '@mui/material'
import { formatDistanceToNow, parseISO } from 'date-fns'
import ActivityAvatar from '../../comps/ActivityAvatar'
import Em from '../../comps/Em'

const Badge = styled(MUIBadge)(
  () => ({
    '&': {
      display: 'block',
      width: '100%',
    },
    '& .MuiBadge-badge': {
      width: '4.5rem',
      height: '4.5rem',
      borderRadius: '100%',
      fontSize: '3rem',
      fontWeight: 900,
      transform: 'translate(-10%, 10%)',
    }
  })
);
const EffortCard = ({effort}) => {
  const [showRedeemPint, setShowRedeemPint] = useState(false);
  const [showRedeemPrize, setShowRedeemPrize] = useState(null);

  const numDraws = Object.keys(effort.segments||{}).length;
  const activity = first(values(effort.activities));
  const canRedeem = effort.pint && !effort.pint.redeemed;
  const prizes = (effort.prizes||[]).filter(p => !p.redeemed);

  return (
    <Badge badgeContent={numDraws} color="primary" anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}>
      <Card>
        <CardHeader
          title={<Typography variant="h6" color="primary" fontWeight="bold" sx={{lineHeight: 1.2}}>
            {activity.name}
          </Typography>}
          subheader={<Typography variant="body1">
            {`${formatDistanceToNow(parseISO(activity.start_date))} ago`}
            {/*{size(effort.segments)} {pluralize('segment', size(effort.segments))}*/}
          </Typography>}
          avatar={<ActivityAvatar activity={activity.type}/>}
          sx={{alignItems: 'flex-start'}}/>
        <CardMedia component="img" image={effort.photo} height={196}/>
        <CardActions sx={{flexDirection: 'row-reverse', justifyContent: 'space-between', alignItems: 'flex-end'}}>
          <Button component="a" href={`https://strava.com/activities/${activity.id}`} target="_blank" rel="noreferrer noopener">View on Strava</Button>
          <Box display="flex" flexDirection="column">
            {canRedeem && <Button variant="contained" onClick={() => setShowRedeemPint(true)} sx={{m:0.25}}>
              Get Your Pint
            </Button>}
            {prizes.map(p => <Button variant="contained" sx={{m:0.25}} onClick={() => setShowRedeemPrize(p.id)}>
              Get Your {p.short_name}
            </Button>)}
          </Box>
        </CardActions>
        {canRedeem && <Dialog
          open={showRedeemPint}
          onClose={() => setShowRedeemPint(false)}
        >
          <DialogTitle>Get your Beer</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Show this QR code to one of the good people at Sheepdog Brewing to redeem your <Em>{effort.pint.item}</Em>.
            </DialogContentText>
            <Box display="flex" justifyContent="center" mt={2}>
              <Box component="img" src={effort.pint.qrcode} alt="" sx={{
                borderColor: 'common.black',
                borderWidth: 4,
                borderStyle: 'solid',
                borderRadius: 2
              }}/>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowRedeemPint(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>}
        {prizes.map(p => <Dialog
          key={p.id}
          open={showRedeemPrize === p.id}
          onClose={() => setShowRedeemPrize(null)}
        >
          <DialogTitle>Get your {p.short_name}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Show this QR code to one of the good people at {p.sponsor.name} to redeem your <Em>{p.name}</Em>.
            </DialogContentText>
            <Box display="flex" justifyContent="center" mt={2}>
              <Box component="img" src={p.qrcode} alt="" sx={{
                borderColor: 'common.black',
                borderWidth: 4,
                borderStyle: 'solid',
                borderRadius: 2
              }}/>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowRedeemPint(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>)}
      </Card>
    </Badge>
  )
}

export default EffortCard;

const EffortCardSkeleton = () => {
  return (
    <Card>
      <CardHeader
        title={<Skeleton variant="rectangluar" width={128} height="1rem"/> }
        avatar={<Skeleton variant="circular" width={24} height={24}/>}
        sx={{alignItems: 'flex-start'}}/>
      <Skeleton variant="rectangluar" height={196}/>
    </Card>
  )
}

export {EffortCardSkeleton};
