import { Box, Typography } from '@mui/material'

const Heading = ({title, extra, ...props}) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end" width="100%" my={1} {...props}>
      <Typography variant="h6" fontWeight={900} color="primary" textTransform="uppercase">{title}</Typography>
      {extra}
    </Box>
  )
}

export default Heading;
