import React from 'react'
import { Box, Stack, SvgIcon } from '@mui/material'
import Section from '../../comps/Section'
import Container from '../../comps/Container'
import { Text1, Text2, Title } from '../../comps/Type'
import { ReactComponent as P4PLogo } from '../../assets/logos/p4p-logo.svg'
import Launcher from '../../comps/Launcher'
import Em from '../../comps/Em'

const Selfies = () => {
  return (
    <Section id="selfies">
      <Container sx={{gap: 8, alignItems:'flex-start'}}>
        <Box display="grid" sx={{
          gridTemplateColumns: {xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'},
          alignItems: 'center',
          gap: 2
        }}>
          <Stack gap={2}>
            <Title>Selfies & Pints</Title>
            <Text1 fontWeight="bold">
              To earn beer, choose a loop, collect your <Em>five</Em> selfies <Em>within 24 hours</Em>, and then head to Sheepdog Brewing to enjoy your free pint.
            </Text1>
            <Text2>
              You will need to complete the loop within 24 hours. The taproom staff at Sheepdog Brewing will check the time stamps on your selfies to verify that you completed the loop within the time frame.
            </Text2>
            <Text2>
              You can complete the loop multiple times with new selfies to earn more free pints throughout the P4P campaign.
            </Text2>
          </Stack>
          <Stack gap={2} alignItems="center">
            <SvgIcon component={P4PLogo} inheritViewBox sx={{
              height: "256px",
              width: "auto"
            }}/>
            <Launcher title="Register Now" href="/signup" size="small"/>
          </Stack>
        </Box>
      </Container>
    </Section>
  )
};

export default Selfies;
