import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import produce from 'immer'
import {
  Alert, AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControlLabel as MUIFormControlLabel,
  Stack,
  styled,
  SvgIcon,
  Typography,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import Root from '../../comps/Root'
import Section from '../../comps/Section'
import Container from '../../comps/Container'
import React, { useEffect, useState } from 'react'
import Spinner from '../../comps/Spinner'
import { SubTitle2, Text2, Text3, Title } from '../../comps/Type'
import ErrorAlert from '../../comps/ErrorAlert'
import TextField from '../../comps/TextField'
import Em from '../../comps/Em'
import { ReactComponent as P4PLogo } from '../../assets/logos/p4p-logo.svg'
import { verifySignup, register } from '../../state'

const TERMS = [
  <Box component="span">
    <Em>I acknowledge</Em> that it is my responsibility to know the <a href="/map" target="_blank" rel="noreferrer noopener">P4P route</a> before I head out, and <Em>I agree</Em> to not hold CAMBA or Sheepdog liable in the event that I go off course.
  </Box>,
  <Box component="span">
    <Em>I agree</Em> to not hold CAMBA or Alberta Parks liable for any incident such as a crash or injury, while participating in this fundraiser.
  </Box>,
  <Box component="span">
    <Em>I acknowledge</Em> that Strava not matching a segment is subject to a variety of factors including GPS inaccuracy or malfunction, or Strava issues, and <Em>I agree</Em> to not hold CAMBA liable in the event that a segment is not matched.
  </Box>,
  <Box component="span">
    <Em>I agree</Em> that in the event of an issue with Strava not matching the P4P loop or a segment, I will not expect Sheepdog to issue me a pint, and will email CAMBA to rectify the issue within 48 hours.
  </Box>,
  <Box component="span">
    <Em>I understand</Em> that this is <Em>not a race</Em>, and will practice good trail etiquette while participating in this fundraiser.
  </Box>,
  <Box component="span">
    <Em>I acknowledge</Em> that, though I can increase my chances of winning I am only eligible to <Em>win a single prize</Em> as part of this fundraiser.
  </Box>
];

const FormControlLabel = styled(MUIFormControlLabel)(
  () => ({
    '&': {
      alignItems: 'center',
      marginRight: 0,
    }
  })
);

const RegisterPage = () => {
  const {token} = useParams();
  const dispatch = useDispatch();
  const [isVerifying, setIsVerifying] = useState(true);
  const [isWorking, setIsWorking] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [error, setError] = useState();
  const [terms, setTerms] = useState(new Array(TERMS.length).fill(false));
  const [showWaiver, setShowWaiver] = useState(false);
  const [info, setInfo] = useState({
    email: '',
    name: '',
    password: '',
    passwordConfirm: '',
    acceptWaiver: false
  });

  const agreeToTerm = (i, v) => {
    setTerms(produce(terms, draft => {
      draft[i] = v;
    }));
  }

  useEffect(() => {
    if (token) {
      dispatch(verifySignup(token)).unwrap()
        .then(email => {
          setInfo({
            ...info,
            email
          });
        })
        .catch(setError)
        .finally(() => setIsVerifying(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const passwordMismatch = info.password.trim().length > 0 && info.passwordConfirm.trim().length > 0 &&
    info.password !== info.passwordConfirm;
  const passwordOk = info.password.trim().length > 0 && info.password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/);

  const isReady = passwordOk && info.name.trim() && terms.every(t => t) && info.acceptWaiver;

  const submit = () => {
    setIsWorking(true);
    dispatch(register(info)).unwrap()
      .then(() => {
        setIsComplete(true);
      })
      .catch(setError)
      .finally(() => setIsWorking(false))
  }

  return (
    <Root>
      <Section>
        <Container maxWidth="md">
          <Stack alignItems="center" gap={2}>
            <SvgIcon component={P4PLogo} inheritViewBox sx={{
              width: "100%",
              height: "auto",
              maxWidth: "512px"
            }}/>
            <Title>Register</Title>
            {isVerifying && <Spinner/>}
            {!isVerifying && <>
              {isComplete && <Alert severity="success" action={<Button component={Link} to="/app">
                Login
              </Button>}>
                <AlertTitle>Registration Complete!</AlertTitle>
                <Text2>You're good to go. You can now proceed to log in to connect Strava and start earning prize draws, if you choose.</Text2>
              </Alert>}
              {error && <ErrorAlert
                title="Sorry, this signup link is not valid." error={error}
                action={<Button component={Link} to="/signup" sx={{textAlign:'center', alignSelf: 'center'}}>Back to Register</Button>}
              />}
              {!isComplete && !error && <Stack gap={2} alignItems="center">
                <Text2>
                  Thanks for signing up for Pedal for Pints. We just need to complete a few housekeeping items before you can go out
                  and start earning beer and prizes.
                </Text2>
                <TextField label="Full Name" type="text" placeholder="Your full name"
                           value={info.name} onChange={(e) => setInfo({...info, name: e.target.value})}
                           fullWidth required />
                <Stack gap={1} sx={{
                  width: '100%', flexDirection: {xs: 'column', sm: 'row'},
                }} >
                  <TextField label="Password" type="password" placeholder="Choose a password"
                             value={info.password} onChange={(e) => setInfo({...info, password: e.target.value})}
                             helperText="Passwords must be at least 8 characters long, with a number and a symbol."
                             fullWidth required error={!passwordOk}/>
                  <TextField label="Password Confirmation" type="password" placeholder="Confirm your password"
                             value={info.passwordConfirm} onChange={(e) => setInfo({...info, passwordConfirm: e.target.value})}
                             fullWidth required error={passwordMismatch}
                             helperText={passwordMismatch?'Passwords do not match':null}/>
                </Stack>
                <Stack>
                  <SubTitle2>Terms & Conditions</SubTitle2>
                  <Text3>
                    Below you'll find the terms, conditions, and points of conduct for the Pedal for Pints fundraiser. Please read and agree to the following terms:
                  </Text3>
                  <Box component="ul" sx={{pl:0, mb:0}}>
                    {TERMS.map((t,i) => <Box key={i} component="li" sx={{listStyleType: 'none', mb:1}}>
                      <FormControlLabel control={<Checkbox value={terms} onChange={e => agreeToTerm(i, e.target.checked)}/>}
                                        label={<Typography>{t}</Typography>}/>
                    </Box>)}
                  </Box>
                </Stack>
                <Stack alignItems="flex-start">
                  <SubTitle2>Waiver</SubTitle2>
                  <Text3>
                    Click the button below to read our waiver of liability. It's the typical legal jargon you would expect, but basically all it's saying is that you can't
                    take legal action against CAMBA, Sheepdog, or Alberta Parks in the event that you injure yourself, or damage any equipment such as your bike, while
                    participating in the fundraiser.
                  </Text3>
                  <Button variant="outlined" size="small" sx={{mt:1}} onClick={() => setShowWaiver(true)}>
                    Read It
                  </Button>
                  <FormControlLabel control={<Checkbox value={info.acceptWaiver} onChange={e => setInfo({
                    ...info,
                    acceptWaiver: e.target.checked
                  })}/>}
                                    label={<Typography color='text.secondary'>By checking this box, <Em>I agree</Em> to the terms and conditions outlined by this Release from Liability and Waiver of all Claims (checked by Parent or Guardian if Participant under 18).</Typography>}
                                    sx={{mt: 4}}
                  />
                </Stack>
                <Button variant="contained" color="primary" size="large" disabled={!isReady || isWorking} onClick={submit}
                        startIcon={isWorking ? <CircularProgress size={24} sx={{color:'inherit'}}/> : <SendIcon/>}>
                  Register
                </Button>
              </Stack>}
            </>}
          </Stack>
        </Container>
        {showWaiver && <Dialog
          open={showWaiver}
          onClose={() => setShowWaiver(false)}
          maxWidth="md"
          PaperProps={{
            sx: {margin: 1, marginTop: 0}
          }}
        >
          <DialogTitle>RELEASE FROM LIABILITY AND WAIVER OF ALL CLAIMS</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{mb:1}}>
              In consideration of Canmore and Area Mountain Bike Association and Sheepdog Brewing ("the Organizers"), collectively accepting my application and my being permitted to participate in an online-based fundraiser known as the "Pedal for Pints" (herein called the "Fundraiser") I agree to this release of claims, waiver of liability and assumption of risks (collectively this "Agreement").
            </DialogContentText>
            <DialogContentText sx={{mb:1}}>
              I waive any and all claims I may have against, and release from all liability and agree not to sue the Organizers for any personal injury, death, property damage or loss sustained by me as a result of my participation in the Fundraiser due to any cause whatsoever including, without limitation: negligence, including failure by the Organizers to use such care as a reasonably prudent and careful guide or event organizer would use under similar circumstances, breach of contract, or breach of any statutory or other duty of care, including any duty of care owed under any applicable Occupier’s Liability legislation on the part of the Organizers, and further including any failure on the part of any Organizer to take reasonable steps to safeguard or protect me from the risks, dangers and hazards of participating in the Fundraiser.
            </DialogContentText>
            <DialogContentText>
              I am aware that participating in the Fundraiser presents dangers and risks, some of which include, but are not limited to:
              <ul>
                <li>Motor Traffic: including negligence of drivers;</li>
                <li>Natural Hazards: including those natural hazards regularly encountered when cycling
                  off-road; and</li>
                <li>Conduct of other participants: conduct of other cyclists, whether participating in the
                  Fundraiser or not.</li>
              </ul>
            </DialogContentText>
            <DialogContentText sx={{mb:1}}>
              I willingly accept all the risks of participating in the Fundraiser, including without limitation the risks described above and the possibility of personal injury, death, property loss or loss resulting therefrom. I acknowledge that injury resulting from these or other risks may be exacerbated by my failure to wear a properly fitting helmet. I acknowledge that the Organizers strongly recommend the use of a helmet for any cycling activity, including the Fundraiser. In entering into this Agreement I am not relying on any oral or written representations or statements made by any Organizer. I acknowledge that the Organizers urge every participant to ride safely and with proper trail etiquette during any Fundraiser.
            </DialogContentText>
            <DialogContentText sx={{mb:1}}>
              I confirm that I am of the Age of Majority (full age of eighteen years) and that I have read and understood this Agreement. I agree that this Agreement shall be effective and binding upon my heirs, next of kin, executors, administrators, assigns and representatives, in the event of my death, and that this Agreement shall be governed by and interpreted in accordance with the laws of the Province of Alberta, and that any litigation involving the parties to this Agreement shall be brought within the Province of Alberta.
            </DialogContentText>
            <DialogContentText sx={{mb:1}}>
              I acknowledge that this Agreement is supplemental to and does not replace any other waiver, indemnity, or other agreement I may have executed in favor of any of the Organizers, their agents or assigns.
            </DialogContentText>
            <DialogContentText>
              <Em>I acknowledge that by accepting these terms I am giving up the right to sue.</Em>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowWaiver(false)}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>}
      </Section>
    </Root>
  )
};

export default RegisterPage;
