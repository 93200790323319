import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Grid, Stack, Typography, Zoom } from '@mui/material'
import {loadData} from '../../state';
import BasePage from './BasePage'
import EffortCard, { EffortCardSkeleton } from './EffortCard'
import Heading from './Heading'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import Em from '../../comps/Em'
import Panel from '../../comps/Panel'


const EffortsPage = () => {
  const user = useSelector(state => state.user);
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data) {
      dispatch(loadData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <BasePage>
        <Heading title="Your Efforts"/>
        <Stack width="100%" spacing={2}>
          <Grid container spacing={2} justifyContent="center">
            {data && data.efforts.map((e,i) => <Grid key={i} item>
              <Zoom direction="up" in={true} style={{
                transitionDelay: `${i*100}ms`
              }}>
                <Box>
                  <EffortCard key={e.id} effort={e}/>
                </Box>
              </Zoom>
            </Grid>)}
            {!data && [1,2,3].map((e, i) => <Grid key={i} item>
              <EffortCardSkeleton/>
            </Grid>)}
          </Grid>
          <Panel
            icon={<RocketLaunchIcon/>}
            title={
              <Typography variant="h4" fontWeight="bold" color="primary" gutterBottom>Something Missing?</Typography>
            }
            text={
              <Box>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  For a number of reasons, including GPS inaccuracy or malfunction, Strava service outages, or other reasons, your effort may not have been picked up by the Pedal for Pints system. Or
                  perhaps it was picked up, but one of the segments didn't match. <Em>Fear Not!</Em>. Just get in touch with us, and we'll get it sorted out.
                </Typography>
              </Box>
            }
            button={<Button variant="outlined" component="a" href={`mailto:contact@camba.ca?subject=P4P Strava Issue - User ${user.strava_id}`}>Email us Now</Button> }
          />
        </Stack>
    </BasePage>
  )
}

export default EffortsPage;
