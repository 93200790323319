import { Avatar as MUIAvatar, Box, Paper, styled, Typography, useMediaQuery, useTheme } from '@mui/material'

const Avatar = styled(MUIAvatar)(
  ({theme}) => ({
    '& svg': {
      fontSize: '4rem'
    }
  })
);


const Panel = ({title, text, icon, iconColor, button}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper sx={{width: '100%', p:isSmall?2:4}} elevation={0}>
      <Box display="flex" flexDirection={isSmall?"column":"row"}>
        {icon && <Avatar sx={{width: '6rem', height: '6rem', backgroundColor: iconColor || 'primary.main'}} >
          {icon}
        </Avatar>}
        <Box sx={{flexGrow: 1, ml:isSmall?0:2}}>
          {title}
          <Box sx={{mb: 2}}>
            {text}
          </Box>
          {button}
        </Box>
      </Box>
    </Paper>
  )
}

export const SubPanel = ({title, children}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Paper sx={{p:isSmall?1:2}}>
      <Typography color="primary" variant="h6" sx={{textTransform: 'uppercase'}} gutterBottom>{title}</Typography>
      {children}
    </Paper>
  )
}

export default Panel;
