import { useState } from 'react'
import {useSelector} from 'react-redux';
import { Link } from 'react-router-dom'
import {
  AppBar,
  Box,
  Collapse,
  IconButton,
  SvgIcon,
  Toolbar,
  useTheme,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import {ReactComponent as Logo} from '../../assets/logos/p4p-logo.svg';
import Avatar from './Avatar';


const Header = () => {
  const theme = useTheme();
  const user = useSelector(state => state.user);
  const isAdmin = user && user.claims.admin === true;

  const [nav, setNav] = useState(false);

  const pages = [{
    name: 'Efforts',
    link: '/app/efforts'
  }, {
    name: 'Leaderboard',
    link: '/app/leaderboard'
  }, {
    name: 'Map',
    link: '/map'
  }];
  if (isAdmin) {
    pages.push({
      name: 'Admin',
      link: '/app/admin'
    });
  }
  return (
    <AppBar position="static" color="primary">
      <Toolbar sx={{justifyContent: 'space-between', py: 1}}>
        <Box component={Link} to="/app" sx={{bgcolor: 'white', borderRadius: '100%'}}>
          <SvgIcon component={Logo} inheritViewBox sx={{
            color: 'initial',
            width: {xs: '4rem', md: '6rem'},
            height: "auto",
            padding: theme.spacing(1),
            // mr: isSmall?0:2
          }}/>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            onClick={() => setNav(!nav)}
            sx={{color: 'primary.contrastText'}}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} justifyContent="flex-end">
          {/*{pages.map((page) => (*/}
          {/*  <Button*/}
          {/*    key={page.name}*/}
          {/*    component={Link} to={page.link}*/}
          {/*    size="large"*/}
          {/*    sx={{ color: 'primary.contrastText', letterSpacing: '0.15rem', my: 2, display: 'block', fontWeight: 900 }}*/}
          {/*  >*/}
          {/*    {page.name}*/}
          {/*  </Button>*/}
          {/*))}*/}
        </Box>

        <Box component="span" pl={2}>
          {user && <Avatar user={user}/>}
        </Box>
      </Toolbar>
      <Collapse in={nav}>
        <Box  justifyContent='space-evenly' px={3} py={1} sx={{
          display: {sx: 'flex', md:'none'}
        }}>
          {/*{pages.map((page) => (*/}
          {/*  <Button*/}
          {/*    key={page.name}*/}
          {/*    component={Link} to={page.link}*/}
          {/*    size="large"*/}
          {/*    sx={{ color: 'common.black', fontWeight: '900', display: 'block' }}*/}
          {/*  >*/}
          {/*    {page.name}*/}
          {/*  </Button>*/}
          {/*))}*/}
        </Box>
      </Collapse>
    </AppBar>
  )
}

export default Header;
