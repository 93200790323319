import {useSelector} from 'react-redux';
import CountUp from 'react-countup';
import pluralize from 'pluralize';
import {Avatar, Box, Grid, Paper, Slide, Typography} from '@mui/material';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Heading from './Heading';


const StatCard = ({value, noun, disabled, Icon, suffix, withPluralize=true}) => {
  return (
    <Slide direction="up" in={true} mountOnEnter>
      <Paper elevation={0}>
        <Box display="grid" gridTemplateColumns="repeat(2,1fr)" alignItems="center" justifyContent="center">
          <Box>
            <Avatar sx={{
              mx: 'auto',
              width: '6rem', height: '6rem',
              backgroundColor: 'secondary.main'
            }}>
              <Icon sx={{fontSize: '4rem'}}/>
            </Avatar>
          </Box>
          <Box px={4} py={8} textAlign="center" sx={{bgcolor: 'grey.100'}}>
            <Typography variant="h3" fontWeight={900} sx={{color:(disabled===true?"grey.300":"primary.main")}} >
              <CountUp end={value} duration={1.5} /> {suffix}
            </Typography>
            <Typography variant="subtitle1" textTransform="uppercase">{withPluralize ? pluralize(noun, value) : noun}</Typography>
          </Box>
        </Box>
      </Paper>
    </Slide>
  )
}
const Stats = () => {
  const user = useSelector(state => state.user);
  const totalDraws = useSelector(state => state.data?.total_draws || 0);
  const numDraws = (user?.num_rides || 0) + (user?.num_runs ||  0);

  return (
    <Box width="100%">
      <Heading title="Your Chances to Win"/>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <StatCard Icon={LocalActivityIcon} value={numDraws} noun="draw"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StatCard Icon={EmojiEventsIcon} value={totalDraws > 0 ? Math.round(numDraws/totalDraws*100) : 0} noun="odds"
                    withPluralize={false} suffix="%"/>
        </Grid>
        {/*<Grid item xs={12} sm={6} md={4}>*/}
        {/*  <StatCard Icon={SportsBarIcon} value={(user.num_pints||0) } noun="pint"/>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} sm={6} md={4}>*/}
        {/*  <StatCard Icon={StarIcon} value={(user.num_efforts||0)} noun="effort"/>*/}
        {/*</Grid>*/}
      </Grid>

      {/*{segments && <Stack mt={1} gap={1}>*/}
      {/*  {segments.filter(s => s.id in user.draws).map(s => {*/}
      {/*    const isDisabled = s.raised < s.target;*/}
      {/*    return <Paper key={s.id} elevation={0}>*/}
      {/*      <Stack sx={{flexDirection: {xs: 'column', sm:'row'}}}>*/}
      {/*        <Box display="flex" justifyContent="center" alignItems="center">*/}
      {/*          <Box component="img" src={s.sponsor.logo} width={128} height={128} p={0.5} sx={{*/}
      {/*            objectFit: 'contain',*/}
      {/*            bgcolor: 'rgba(255,255,255,0.9)',*/}
      {/*            }}>*/}
      {/*          </Box>*/}
      {/*          <Box position="relative">*/}
      {/*            <Box component="img" src={s.prize.image} width={128} height={128} sx={{*/}
      {/*              objectFit: 'contain',*/}
      {/*              bgcolor: 'rgba(255,255,255,0.9)',*/}
      {/*              }}>*/}
      {/*            </Box>*/}
      {/*            {isDisabled && <SegmentLockIcon segment={s} sx={{*/}
      {/*              position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'*/}
      {/*            }}/>}*/}
      {/*          </Box>*/}
      {/*        </Box>*/}
      {/*        <Stack sx={{flexGrow:1}} p={1} gap={1}>*/}
      {/*          <Box>*/}
      {/*            <Typography variant="h6" fontWeight={900} lineHeight={1}>{s.prize.name}</Typography>*/}
      {/*            <Typography variant="body1">{s.name}</Typography>*/}
      {/*          </Box>*/}
      {/*          <SegmentProgress segment={s}/>*/}
      {/*          {isDisabled && <Caption sx={{color: 'quaternary.main'}}>This segment is currently locked. Until it is unlocked by hitting the donation target, prize draws earned will not be active.</Caption>}*/}
      {/*        </Stack>*/}
      {/*        <Box p={2} textAlign="center">*/}
      {/*          <Typography variant="h3" fontWeight={900} sx={{color:(isDisabled?"grey.300":"primary.main")}} >*/}
      {/*            <CountUp end={user.draws[s.id]} duration={3} />*/}
      {/*          </Typography>*/}
      {/*          <Typography variant="subtitle1" textTransform="uppercase">{pluralize('draw', user.draws[s.id])}</Typography>*/}
      {/*          <Button variant="contained" color="primary" href={segmentDonateLink(s)} sx={{mt:2}}>*/}
      {/*            Donate*/}
      {/*          </Button>*/}
      {/*        </Box>*/}
      {/*      </Stack>*/}
      {/*    </Paper>*/}
      {/*  })}*/}
      {/*</Stack>}*/}
    </Box>
  )
}

export default Stats;
