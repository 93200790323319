import React from 'react'
import { Box, Stack, SvgIcon } from '@mui/material'
import Container from '../../comps/Container'
import { Text1, Text2, Title } from '../../comps/Type'
import Launcher from '../../comps/Launcher'
import Section from '../../comps/Section'
import { ReactComponent as P4PLogo } from '../../assets/logos/p4p-logo.svg'

const Strava = () => {
  return (
    <Section id="strava">
      <Container sx={{gap: 8, alignItems:'flex-start'}}>
        <Box display="grid" sx={{
          gridTemplateColumns: {xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'},
          alignItems: 'center',
          gap: 2
        }}>
          <Stack gap={2}>
            <Title>Strava & Prizes</Title>
            <Text1 fontWeight="bold">
              If all you're after is free beer, you can skip this part and stick to <a href="#selfies">Selfies & Pints</a>. To earn prizes, you
              need to connect your Strava account, and pay a small entry fee.
            </Text1>
            <Text2>
              To earn prize draws, all you need to do is ride one of the segments on the loop. Each segment you ride earns you a draw. One segment = one draw.
            </Text2>
            <Text2>
              This year, we are simplifying things with a small entry fee up front that allows participants to earn as many draws as they want.
            </Text2>
            <Text2>
              Complete any of the segments to be entered into the prize draws. Think of it like a raffle ticket where your chances of winning increase the more that you ride.
            </Text2>
          </Stack>
          <Stack gap={2} alignItems="center">
            <SvgIcon component={P4PLogo} inheritViewBox sx={{
              height: "256px",
              width: "auto"
            }}/>
            <Launcher title="Register Now" href="/signup" size="small"/>
          </Stack>
        </Box>
      </Container>
    </Section>
  )
};

export default Strava;
